import React, { Component } from 'react';
// import { data } from "ClientApp/src/assets/homePageData";
import { data } from "./homePageData";
import "animate.css/animate.compat.css";
import ScrollAnimation from "react-animate-on-scroll";
import { CustomImageSwiper } from './CustomImageSwiper/CustomImageSwiper';

const teamMembersPerRow = 4;
const teamMembersInLastRow = data.team.length % teamMembersPerRow;
const teamMembersBeforeLastRow = data.team.length - teamMembersInLastRow;

export class Home extends Component {

	// teamMembersPerRow = 4;
	// teamMembersInLastRow = data.team.length % this.teamMembersPerRow;
	// teamMembersBeforeLastRow = data.team.length - this.teamMembersInLastRow;

	render() {
		return (
			<div>
				<div className='at-back position-top height-70' id='imageSlide'>
					<CustomImageSwiper />
				</div>
				<div className="justify-content-center text-theme margin-top-50">
					<div className="flex-column">
						<div className="">
							<h1 className='center text-theme'>Our Products</h1>

							<div className="panel-4item-row center">
								{data.products.map((data, key) => {
									return (
										<div key={key} className="">
											<ScrollAnimation
												animateOnce={true}
												animateIn="zoomIn"
												duration={2}
											// animateOut="lightSpeedOut"
											>
												<div className="tight-box-shadow">
													<div className="">
														<img src={"./images/" + data.image} width="150px" height="150px" alt="Service Icon" />
													</div>
													<div className='text-container1'>
														<p>{data.name}</p>
													</div>
												</div>
											</ScrollAnimation>
										</div>
									);
								})}
							</div>
						</div>
						<div className="panel-type1">
							<h1 className='center text-theme'>Service from Us</h1>

							<div className="flex-row service-box-panel service-panel-size center">
								{data.services.map((data, key) => {
									return (
										<div key={key} className="service-box row-2items pad20 center">
											<ScrollAnimation
												animateOnce={true}
												animateIn="zoomIn"
												duration={2}
											// animateOut="lightSpeedOut"
											>
												<div className="image-container1 center">
													<img src={"./images/" + data.image} alt="Service Icon" />
												</div>
												<h2>{data.title}</h2>
												<p className='pad10'>{data.description}</p>
											</ScrollAnimation>
										</div>
									);
								})}
							</div>
						</div>
						<div className="panel-type1">
							<h1 className='center text-theme'>Our Vision & Mission</h1>

							<div className="flex-row service-box-panel vision-panel-size center">
								{data.visions.map((data, key) => {
									return (
										<div key={key} className="service-box row-2items pad20 center">
											<ScrollAnimation
												animateOnce={true}
												animateIn="bounceInLeft"
												duration={2}
											// animateOut="lightSpeedOut"
											>
												<h2>{data.title}</h2>
												<ol className='text-justify'>
													{data.values.map((value) =>
														<li>{value}</li>
													)}
												</ol>
											</ScrollAnimation>
										</div>
									);
								})}
							</div>
						</div>

						<div className="">
							<h1 className='center text-theme'>Our Team</h1>

							<div className="panel-4item-row center">
								{data.team.map((data, key) => {
										return (
											<div key={key} className="">
												<ScrollAnimation
													animateOnce={true}
													animateIn={
														window.innerWidth > 768
															? key % 4 < 2 ? "fadeInLeft" : "fadeInRight"
															: key % 2 === 0 ? "fadeInLeft" : "fadeInRight"
													}
													// animateIn="bounceInRight"
													duration={2}
												// animateOut="lightSpeedOut"
												>
													<div className="box-shadow1">
														<div className="center">
															<img src={"./images/" + data.image} width="50%" height="120px" alt="Service Icon" />
														</div>
														<h2>{data.name}</h2>
														<p className="p1">{data.role}</p>
														{/* <div className="justify-content-center center">
															<div className="social-icon-div center">
																<img className="social-icon" src={"./images/instagram.svg"} width="25%" alt="Instagram Icon" />
															</div>
															<div className="social-icon-div center">
																<img className="social-icon" src={"./images/facebook.svg"} width="25%" alt="Facebook Icon" />
															</div>
														</div> */}
													</div>
												</ScrollAnimation>
											</div>
										);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
