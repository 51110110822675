import React, { useState } from 'react';
import Logo from "../assets/logo.png";

export default function Footer() {
    return (
        <>
            {/* // <!-- Remove the container if you want to extend the Footer to full width. --> */}
            {/* <div className="container mt-5"> */}
            <div className='footer-style1'>
                <img style={{ marginLeft: "-10px", marginBottom: "-10px" }} src={"./images/footer_wave.svg"} alt="Service Icon" />
                <footer className="text-center text-lg-start text-white"
                    style={{ backgroundColor: '#0c2e04' }}>
                    <div className="container p-4">
                        <div className="row footer-container-style1">
                            <div className="col-lg-4 col-md-6 mb-4 mb-md-0">

                                <div className="shadow-1-strong d-flex align-items-center justify-content-center mb-4 mx-auto" style={{ width: '3rem', height: '3rem' }}>
                                    {/* <img src="https://mdbootstrap.com/img/Photos/new-templates/animal-shelter/logo.png" height="70" alt=""
                                        loading="lazy" /> */}
                                    <a href="#">
                                        <img src={Logo} height="100" alt="logo" />
                                        {/* <Logo className="logo" /> */}
                                    </a>
                                </div>

                                <p className="text-center pr-5">To Strengthening Institutional linkages of medicinal plant growers</p>
                                <p className="text-center pr-5">Capacity building of the stakeholders about medicinal plants cultivation</p>

                                <ul className="list-unstyled d-flex flex-row justify-content-center left-right-padding-2">
                                    <li>
                                        <a className="text-white px-2" href="#!">
                                            <div className="social-icon-div center">
                                                <img className="social-icon-footer" src={"./images/facebook.svg"} alt="Service Icon" />
                                            </div>
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                            </svg> */}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white px-2" href="#!">
                                            <div className="social-icon-div center">
                                                <img className="social-icon-footer" src={"./images/instagram.svg"} alt="Service Icon" />
                                            </div>
                                            {/* <img src="" height="70" alt="Insta"
                                                loading="lazy" /> */}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="text-white ps-2" href="#!">
                                            <div className="social-icon-div center">
                                                <img className="social-icon-footer" src={"./images/linkedin.svg"} alt="Service Icon" />
                                            </div>
                                            {/* <img src="" height="70" alt="YT"
                                                loading="lazy" /> */}
                                        </a>
                                    </li>
                                </ul>

                            </div>

                            <div className="col-lg-1 col-md-6 mb-4 mb-md-0"></div>

                            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                                <h5 className="text-uppercase mb-4">Contact</h5>

                                <ul className="list-unstyled">
                                    <li>
                                        <p><i className="fas fa-map-marker-alt"></i>NAVFPC, C/o. CARD Office, Near Rest House, Anjangaon Surji,
                                            Amravati (444705), Maharashtra, India.</p>
                                    </li>
                                    <li>
                                        <p><i className="fa-solid fa-phone"></i>+91 9822724939 / 9022006821</p>
                                    </li>
                                    <li>
                                        <p><i className="fas fa-envelope mb-0"></i>navfpc@gmail.com</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-1 col-md-6 mb-4 mb-md-0"></div>

                            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                                <h5 className="text-uppercase mb-4">Working Hours</h5>

                                <ul className="list-unstyled">
                                    <li className="mb-2">
                                        <p tyle={{ color: "#4f4f4f" }}>Monday – Saturday (10.00 am - 5.00 pm)</p>
                                    </li>
                                </ul>
                            </div>


                        </div>
                    </div>

                    <div className="text-center p-3 text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        {/* © 2023 Copyright: */}
                        Copyright © 2023 NAVFPC Commodity |
                        Developed By
                        <a className="text-white" href="https://mdbootstrap.com/"> Akshay Ambadkar - 9096336372</a>
                    </div>
                </footer>
            </div>
        </>
    )
}