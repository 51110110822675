import React, { useState } from 'react';
// import { data } from "ClientApp/src/assets/homePageData";
import products from "./ProductData.json";
// import "animate.css/animate.compat.css";
// import { CustomImageBackground } from './CustomImageBackground';
// import { ProductImageSwiper } from './ProductImageSwiper/ProductImageSwiper';

import 'bootstrap/dist/css/bootstrap.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ProductDetails } from './ProductDetails';
import { isMobile } from 'react-device-detect';

// const product = products.filter(obj => {
// 	return obj.ProductName === 'Long Pepper (Spikes)';
// });

function Products() {

	const [key, setKey] = useState("0");

	// render() {
	return (
		<div className='margin-btm-40'>
			{key !== "0" ? null :
				<div className="margin-top-50">
					<h1 className='center text-theme margin-btm-20'>Our Products</h1>

					<div className="products-panel-row center">
						{products.map((product, key) => {
							return (
								<div className="product-box-shadow">
									<div className="">
										<img src={"./images/products/" + product.ProductName + ".jpg"} width="150px" height="150px" alt="Service Icon" />
									</div>
									<div className='text-container1'>
										<p>{product.ProductName}</p>
									</div>
									<button onClick={() => setKey(product.Id)} className='product-button'>View Details</button>
								</div>
							);
						})}
					</div>
				</div>
			}
			{key === "0" ? null :
				<div className='product-tabs'>
					<Tabs
						id="controlled-tab-example"
						activeKey={key}
						onSelect={(k) => setKey(k)}
						className="mb-3"
						// variant="pills"
						fill={isMobile}
					>
						{products.map(obj => {
							return (
								<Tab eventKey={obj.Id} title={obj.ProductName}>
									<ProductDetails item={{ productDetails: obj }} />
								</Tab>
							)
						})}
					</Tabs>
				</div>
			}
		</div>
	);
	// }
}

export default Products;
