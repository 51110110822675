import React, { useState } from "react";
import StartFirebase from "./firebaseConfig";
import { getDatabase, ref, set, get, update, remove, child, push } from "firebase/database";
import AdminCrud from './AdminCrud';

let uniqueNo=0;
class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            db: '',
            dbLeads: [],
            
        }
        this.interface = this.interface.bind(this);
    }

    componentDidMount() {
        this.setState({
            db: StartFirebase()
        });
        this.selectData();
    }

    render() {
        return (
            <>
                <div >
                    <div className="container">
                        <table id="leadsTable" className="display table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Mobile number</th>
                                    <th>Email</th>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Date and Time</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.dbLeads.map((row,index) => {
                                    return (
                                        <tr key={uniqueNo++}>
                                            <td>{index+1}</td>
                                            <td>{row.data.Name}</td>
                                            <td>{row.data.MobileNo}</td>
                                            <td>{row.data.Email}</td>
                                            <td>{row.data.Subject}</td>
                                            <td>{row.data.Message}</td>
                                            <td>{row.data.DateTime}</td>
                                            <td><AdminCrud id={row.key} record={row.data}/></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }

    interface(event) {
        const id = event.target.id;

        if (id == 'addBtn') {
            this.insertData();
        }
        else if (id == 'GetBtn') {
            this.selectData();
        }
        else if (id == 'deleteBtn') {
            this.deleteData();
        }
        else if (id == 'updateBtn') {
            this.updateData();
        }
    }

    selectData() {
        // writeUserData(2, 'Akshay', 'AkshayAmbadkar@gmail.com');
        // writeNewPost('Akshay', 'AkshayAmbadkar@gmail.com');

        const dbref = ref(getDatabase());

        get(child(dbref, 'Leads')).then((snapshot) => {
            const dbLeadsData = [];
            if (snapshot.exists()) {
                snapshot.forEach((child) => {
                    dbLeadsData.push({ "key": child.key, "data": child.val() });
                });
                this.setState({ dbLeads: dbLeadsData });
            }
            else {
                alert('no data found');
            }
        })
            .catch((error) => alert(error));
    }



    //   function writeUserData(userId, name, email) {
    //     const db2 = getDatabase();
    //     set(ref(db2, 'users/' + userId), {
    //       Name: name,
    //       Email: email
    //     });
    //   }
    //   function writeNewPost(name, email) {
    //     debugger;
    //     const db = getDatabase();

    //     // A post entry.
    //     const postData = {
    //       Name: name,
    //       Email: email
    //     };

    //     // Get a key for a new Post.
    //     const newPostKey = push(child(ref(db), 'users')).key;
    //     //const newPostKey=PostKey++;

    //     console.log(newPostKey);
    //     // Write the new post's data simultaneously in the posts list and the user's post list.
    //     const updates = {};
    //     updates['/posts/' + newPostKey] = postData;
    //     updates['/user-posts/' + 1 + '/' + newPostKey] = postData;

    //      return update(ref(db), updates);
    //   }
}


export default Admin;