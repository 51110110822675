export const data = {
	services: [
		{
			title: "Competent",
			description: "Medicinal Plants are useful in various Human and Livestock ailments. It is a crucial ecological and economical alternative health system. These plants are knowledge depositories. We consistently impart this knowledge along with the good agricultural practices at farm level and at the end the users.",
			image: "competency.svg"
		},
		{
			title: "Quick & Precise",
			description: "We are processing the needs at domestic and international level rapidly as we are located in Vidarbha region of Maharashtra, India. We are able to address the issues and demand rapidly.",
			image: "quick1.svg"
		},
		{
			title: "Best Quality",
			description: "Our commodities are the best, directly picked by cultivators, cooperate with cultivators and collectors who are competent in their fields.",
			image: "quality.svg"
		},
		{
			title: "Best Team",
			description: "Our Team consists of people who are competent in their fields and have good skills in medicinal plants cultivation. Our Suppliers are also the best suppliers.",
			image: "team2.svg"
		},
	],
	visions: [
		{
			title: "Vision",
			values: [
				"To Strengthening Institutional linkages of medicinal plant growers.",
				"Capacity building of the stakeholders about medicinal plants cultivation"
			]
		},
		{
			title: "Mission",
			values: [
				"Interphase of the Medicinal Plant Growers with various stakeholders in the sector",
				"Institutionalized approach to address the constraints of medicinal plant growers",
				"Technical, Financial and Social support for area expansion, productivity improvement and successful marketing",
				"Established the appropriate value chain of the medicinal plant grown in the region"
			]
		},
	],
	team: [		
		{
			name: "Mr. K. B. Ladole",
			role: "Promoter and Director",
			image: "team/Kamalkant Ladole.png",
			// instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. N. P. Awandkar",
			role: "Promoter and Director",
			image: "team/Nandkishor Awandkar.png",
			// instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mrs. M. S. Thorat",
			role: "Promoter and Director",
			image: "team/Mandatai Thorat.png",
			// instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. D. N. Bhopale",
			role: "Promoter and Director",
			image: "team/Dilip Bhople.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. M. K. Murkute",
			role: "Promoter and Director",
			image: "team/Manohar Murkute.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. S. M. Thorat",
			role: "Promoter and Director",
			image: "team/Subhash Thorat.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. D. V. Sune",
			role: "Promoter and Director",
			image: "team/Dnyeshwar Sune.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. G. B. Gujar",
			role: "Promoter and Director",
			image: "team/Ganesh Gujar.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. N. U. Rekhate",
			role: "Promoter and Director",
			image: "team/Nandkishor Rekhate.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. R. C. Aswar",
			role: "Promoter and Director",
			image: "team/Mr. R. C. Aswar.jpg",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. M. N. Bhave",
			role: "Promoter and Director",
			image: "team/Manohar Bhave.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Dr Mangesh J Dagawal",
			role: "Consultant",
			image: "team/Dr Mangesh J Dagawal.jpeg",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		{
			name: "Mr. V. B. Ladole",
			role: "Mentor",
			image: "team/V B Ladole.png",
			//instagramLink: "https://instagram.com/",
			// facebookLink: "https://www.facebook.com/"
		},
		// {
		// 	name: "Ms. D. Roshani",
		// 	role: "CEO",
		// 	image: "team/Ms. D. Roshani.jpg",
		// 	//instagramLink: "https://instagram.com/",
		// 	// facebookLink: "https://www.facebook.com/"
		// },
		
	],
	products: [
		{
			name: "Long Pepper (Spikes)",
			image: "products/Long Pepper (Spikes).jpg"
		},
		{
			name: "Long Pepper (Roots & Stem)",
			image: "products/Long Pepper (Roots & Stem).jpg"
		},
		{
			name: "Cassia Tora (Sennatora)",
			image: "products/Cassia Tora (Sennatora).jpg"
		},
		{
			name: "Velvet bean (Mucuna Pruriens)",
			image: "products/Velvet bean (Mucuna Pruriens).jpg"
		},
	],
	aboutUs: {
		companyName: "",
		contactNumbers: [
			"+919822724939",
			"+919022006821",
		],
		email: "navfpc@gmail.com",
		addresses: [
			{
				type: "Head Office",
				detail: "NAVFPC, C/o. CARD Office, Near Rest House, Anjangaon Surji, Amravati (444705), Maharashtra, India."
			}
		],
		workingHours: "Monday - Saturday (10.00 am - 5.00 pm)"
	}

}