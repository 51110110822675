import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

function StartFirebase(){
const firebaseConfig = {
  apiKey: "AIzaSyDTd9o5fPZTn5hKtc_yVO6d_oF_ev1A7tc",
  authDomain: "navfpc-webapp.firebaseapp.com",
  databaseURL: "https://navfpc-webapp-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "navfpc-webapp",
  storageBucket: "navfpc-webapp.appspot.com",
  messagingSenderId: "407743472026",
  appId: "1:407743472026:web:eeca18b046abccc254da23",
  measurementId: "G-VBXVGRLBCD"
};

const app = initializeApp(firebaseConfig);

return getDatabase(app);

}

export default StartFirebase;