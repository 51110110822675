import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import GoogleMap from './GoogleMap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FloatingButtons from './FloatingButtons.css';

// import GoogleMap from './GoogleMap';
import Footer from './Footer';
// import Corousel from './Corousel';

const initialState = {
    Name: "",
    MobileNo: "",
    Email: "",
    // Subject: "",
    Message: ""
}

export default function ContactUs() {

    const [fieldValue, setFieldValue] = useState(initialState);
    const [fieldErrors, setFieldErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const timestamp = Date.now();
    const DateTime = new Intl.DateTimeFormat
        ('en-US', {
            year: 'numeric', day: '2-digit', month: '2-digit', hour: '2-digit'
            , minute: '2-digit', second: '2-digit'
        }).format(timestamp);
    // const { Name, MobileNo, Email, Subject, Message } = fieldValue;
    const { Name, MobileNo, Email, Message } = fieldValue;


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFieldValue({ ...fieldValue, [name]: value });
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();

        setFieldErrors(validate(fieldValue));
        setIsSubmit(true);
    };

    useEffect(() => {

        if (Object.keys(fieldErrors).length === 0 && isSubmit) {

            const leadsResponce = fetch(
                "https://navfpc-webapp-default-rtdb.asia-southeast1.firebasedatabase.app/Leads.json", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    //Name, MobileNo, Email, Subject, Message, DateTime
                    Name, MobileNo, Email, Message, DateTime
                })
            }
            )
            if (leadsResponce) {
                setFieldValue({
                    Name: "",
                    MobileNo: "",
                    Email: "",
                    //Subject: "",
                    Message: ""
                })
                toast.success("Data saved Sucessfully!");
            }
        }
    }, [fieldErrors]);

    const errors = {}
    const validate = (values) => {
        if (!values.Name) {
            errors.Name = "Name is reqired!";
        }
        // else if (!(/^[a-z][a-z\s]*$/.test(values.Name))) {
        //     errors.Name = "Please enter valid Name!";
        // }
        if (!values.MobileNo) {
            errors.MobileNo = "Mobile number is reqired!";
        } else if (!(/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/.test(values.MobileNo))) {
            errors.MobileNo = "Please enter valid Mobile number!";
        }
        if (!values.Email) {
            errors.Email = "Email is reqired!";
        } else if (!(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(values.Email))) {
            errors.Email = "Please enter valid email!";
        }
        // if (!values.Subject) {
        //     errors.Subject = "Subject is reqired!";
        // }
        if (!values.Message) {
            errors.Message = "Message is reqired!";
        }
        return errors;
    }

    return (
        <>
            {/* <Corousel /> */}
            <ToastContainer position='top-center' />
            {/* <Corousel /> */}
            {/* Horizontal lines and text */}
            <div className="container p-4 text-center">
                <div className="row my-4">
                    <div className="col-lg-2 col-md-1 mb-4 mb-md-0"></div>
                    <div className="col-lg-8 col-md-6 mb-4 mb-md-0">
                        <hr className='text-center ml-5 mr-5'
                            style={{
                                background: 'Black',
                                color: 'Black',
                                borderColor: 'Black',
                                height: '2px',
                            }}
                        />
                        <div className="text-center">
                            <p className='d-inline fw-bolder'>Have a question? We love helping others. Feel free to get in touch with us</p>
                            <p className='fw-bolder'>if you have any queries or suggestions.</p>
                        </div>
                        <hr className='text-center ml-5 mr-5'
                            style={{
                                background: 'Black',
                                color: 'Black',
                                borderColor: 'Black',
                                height: '2px',
                            }}
                        />
                    </div>
                    <div className="col-lg-2 col-md-1 mb-4 mb-md-0"></div>
                </div>
            </div>

            <GoogleMap />

            <div
                className="bg-image card shadow-1-strong contact-us-style1"
                //style={{ backgroundImage: "url('https://mdbootstrap.com/img/Photos/Slides/img%20(27).jpg')" }}
                style={{ backgroundImage: "url('images/AboutUs/Big Images.jpg')" }}
            >
                <Row className='d-flex justify-content-center container-fluid contact-us-style1'
                    style={{ backgroundColor: 'rgba(150, 150, 150, 0.3)' }}
                >
                    <Col className='mt-5 text-GrayText col-lg-2 col-md-2'></Col>
                    <Col md='5' className='mt-5 text-GrayText col-lg-4 col-md-6'>
                        <h3 style={{ color: 'White' }}>Send Message</h3>
                        <div class="">
                            {/* <div className="did-floating-label-content">
                                <input
                                    className="did-floating-input"
                                    id='formName'
                                    type='text'
                                    onChange={handleInputChange}
                                    name='Name'
                                    value={fieldValue.Name}
                                    required
                                    placeholder=" " />
                                <label className="did-floating-label">Name</label>
                                <p className="text-danger">{fieldErrors.Name}</p>
                            </div> */}
                            <div class="form-floating mb-3">
                                <input type="text"
                                    className="form-control"
                                    style={{ backgroundColor: 'rgba(255,255,255, 1)' }}
                                    id="formName"
                                    name='Name'
                                    onChange={handleInputChange}
                                    value={fieldValue.Name}
                                    placeholder=" "
                                />
                                <label for="formName" style={{ color: 'GrayText' }}>Name</label>
                                <p className="text-danger">{fieldErrors.Name}</p>
                            </div>
                            {/* <div className="did-floating-label-content">
                                <input className="did-floating-input"
                                    id='formMobileNo'
                                    type='text'
                                    onChange={handleInputChange}
                                    name='MobileNo'
                                    value={fieldValue.MobileNo}
                                    placeholder=" " />
                                <label className="did-floating-label">Mobile Number</label>
                                <p className="text-danger">{fieldErrors.MobileNo}</p>
                            </div> */}
                            <div class="form-floating mb-3">
                                <input type="text"
                                    className="form-control"
                                    style={{ backgroundColor: 'rgba(255,255,255, 1)' }}
                                    id="formMobileNo"
                                    name='MobileNo'
                                    onChange={handleInputChange}
                                    value={fieldValue.MobileNo}
                                    placeholder="+919999999999"
                                />
                                <label for="formMobileNo" style={{ color: 'GrayText' }}>Mobile Number</label>
                                <p className="text-danger">{fieldErrors.MobileNo}</p>
                            </div>
                            {/* <div className="did-floating-label-content">
                                <input className="did-floating-input"
                                    id='formEmail'
                                    type='email'
                                    onChange={handleInputChange}
                                    name='Email'
                                    value={fieldValue.Email}
                                    placeholder=" " />
                                <label className="did-floating-label">Email</label>
                                <p className="text-danger">{fieldErrors.Email}</p>
                            </div> */}
                            <div class="form-floating mb-3">
                                <input type="email"
                                    className="form-control"
                                    style={{ backgroundColor: 'rgba(255,255,255, 1)' }}
                                    id="formEmail"
                                    name='Email'
                                    onChange={handleInputChange}
                                    value={fieldValue.Email}
                                    placeholder="name@example.com"
                                />
                                <label for="formEmail" style={{ color: 'GrayText' }}>Email address</label>
                                <p className="text-danger">{fieldErrors.Email}</p>
                            </div>
                            {/* <div className="did-floating-label-content">
                                <input className="did-floating-input"
                                    id='formSubject'
                                    type='text'
                                    onChange={handleInputChange}
                                    name='Subject'
                                    value={fieldValue.Subject}
                                    placeholder=" " />
                                <label className="did-floating-label">Subject</label>
                            </div> */}
                            {/* <div class="form-floating mb-3">
                                <input type="text" 
                                    className="form-control" 
                                    style={{backgroundColor:'rgba(0,0,0,0)'}} 
                                    id="formSubject"
                                    name='Subject'
                                    onChange={handleInputChange}
                                    value={fieldValue.Subject}
                                    placeholder=" " 
                                />
                                <label for="formSubject" style={{ color: 'GrayText' }}>Subject</label>
                                <p className="text-danger">{fieldErrors.Subject}</p>
                            </div> */}
                            {/* <div className="did-floating-label-content">
                                <textarea
                                    className="did-floating-input"
                                    id='formMessage'
                                    type='text'
                                    rows={4}
                                    onChange={handleInputChange}
                                    name='Message'
                                    value={fieldValue.Message}
                                    placeholder=" "
                                />
                                <label className="did-floating-label">Your Message</label>
                                <p className="text-danger">{fieldErrors.Message}</p>
                            </div> */}
                            <div className="form-floating">
                                <textarea
                                    className="form-control"
                                    placeholder="Leave a comment here"
                                    id="formMessage"
                                    type='text'
                                    onChange={handleInputChange}
                                    name='Message'
                                    value={fieldValue.Message}
                                    style={{ backgroundColor: 'rgba(255,255,255, 1)' }}
                                />
                                <label for="formMessage" style={{ color: 'GrayText' }}>Your Message</label>
                                <p className="text-danger">{fieldErrors.Message}</p>
                            </div>
                        </div>

                        {/* <input
                            className='mt-2 text-GrayText'
                            label='Name'
                            id='formName'
                            type='text'
                            onChange={handleInputChange}
                            name='Name'
                            value={fieldValue.Name}
                            required
                        />
                        <p className="text-danger">{fieldErrors.Name}</p>
                        <input
                            className='mt-2 text-GrayText'
                            label='MobileNo'
                            id='formMobileNo'
                            type='text'
                            onChange={handleInputChange}
                            name='MobileNo'
                            value={fieldValue.MobileNo}
                            required
                        />
                        <p className="text-danger">{fieldErrors.MobileNo}</p>
                        <input
                            className='mt-2 text-GrayText'
                            label='Email'
                            id='formEmail'
                            type='email'
                            onChange={handleInputChange}
                            name='Email'
                            value={fieldValue.Email}
                        />
                        <p className="text-danger">{fieldErrors.Email}</p>
                        <input
                            className='mt-2 text-GrayText'
                            label='Subject'
                            id='formSubject'
                            type='text'
                            onChange={handleInputChange}
                            name='Subject'
                            value={fieldValue.Subject}
                        />
                        <p className="text-danger">{fieldErrors.Subject}</p>
                        <textarea
                            className='mt-2 text-GrayText'
                            label='Your Message'
                            id='formMessage'
                            rows={4}
                            onChange={handleInputChange}
                            name='Message'
                            value={fieldValue.Message}
                        />
                        <p className="text-danger">{fieldErrors.Message}</p> */}
                        <Button type="submit" onClick={handleSubmitClick} className='mt-2 mb-4 btn btn-success' color='#17202A'>Submit</Button>

                    </Col>

                    <Col md='5' className='mt-5 text-White col-lg-5 col-md-6' style={{ fontSize: '1rem' }}>
                        <Row className='ms-4 '>
                            <h3 style={{ color: 'White' }}>Contact Information</h3>
                        </Row>
                        <ul>
                            <div style={{ color: 'White' }} >

                                <Row breakpoint="md">
                                    <Col md='1'>
                                        <img fas icon="phone-square-alt" className='ms-1 ' size='lg' />
                                    </Col>
                                    <Col md='8'>
                                        <p className='ms-1 d-inline fw-bolder'>Phone</p>
                                        <p className='ms-1'>+91 9822724939 / 9022006821</p>
                                    </Col>
                                </Row>

                                <Row breakpoint="md">
                                    <Col md='1'>
                                        <img fas icon="user-clock" className='ms-1 ' size='lg' />
                                    </Col>
                                    <Col md='8'>
                                        <p className='ms-1 d-inline fw-bolder'>Working Hours</p>
                                        <p className='ms-1'>Monday – Saturday (10.00 am - 5.00 pm)</p>
                                    </Col>
                                </Row>

                                <Row breakpoint="md">
                                    <Col md='1'>
                                        <img fas icon="at" className='ms-1 ' size='lg' />
                                    </Col>
                                    <Col md='8'>
                                        <p className='ms-1 d-inline fw-bolder'>Email</p>
                                        <p className='ms-1'>navfpc@gmail.com</p>
                                    </Col>
                                </Row>

                                <Row breakpoint="md">
                                    <Col md='1'>
                                        <img fas icon="map-pin" className='ms-1 ' size='lg' />
                                    </Col>
                                    <Col md='8'>
                                        <p className='ms-1 d-inline fw-bolder'>Office Address</p>
                                        <p className='ms-1'>NAVFPC, C/o. CARD Office, Near Rest House, Anjangaon Surji,
                                            Amravati (444705), Maharashtra, India.</p>
                                    </Col>
                                </Row>
                            </div>
                        </ul>

                    </Col>
                </Row>
            </div>
        </>
    );
}