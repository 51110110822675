import React, { Component, } from 'react';


const showInMapClicked = () => {
    window.open("https://maps.google.com?q=" + 21.169870523982542 + "," + 77.31365468069478);
};
//21.169870523982542, 77.31365468069478
//21.169870523982542,77.31365468069478

export const GoogleMap = () => {
    return (
        <>
        <div className='row'>
            <div className="col-lg-2 col-sm-0"></div>
            <div className='col-lg-8 col-md-12 text-center'>
                <div className='bg-image hover-zoom text-center m-4'>
                    <img src='images/ContactUs/CardOfficeNAVFPC.png' className='w-100 img-fluid' />
                </div>
                <div className="col-6 mx-auto text-center">
                    <button type="button" className="col-sm-4 btn btn-danger mb-5 w-50 h-100" size='lg' onClick={showInMapClicked}>Open Map</button>
                </div>
            </div>
            <div className="col-lg-2 col-sm-0"></div>
        </div>
        </>
    );
}
export default GoogleMap; 