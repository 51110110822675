import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./ProductImageSwiper.css";

import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import { isMobile } from "react-device-detect";

// const slide1Obj = {
// 	element: <img src={"./images/slides/slide1.jpg"} alt='nature-1' />,
// 	animeOrNot: "false"
// };

export class ProductImageSwiper extends Component {

	imagePaths = this.props.item.imagePaths;

	render() {
		return (
			<div className="product-image-swiper">
				<Swiper
					grabCursor={true}
					slidesPerView={isMobile ? 1 : 3}
					spaceBetween={30}
					loop={true}
					pagination={{
						clickable: true,
					}}
					navigation={true}
					modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
					className="mySwiper"
				>
					{/* <SwiperSlide>
						<img src={"./images/slides/slide1.jpg"} alt='nature-1' />
					</SwiperSlide> */}
					{this.imagePaths.map(imagePath => {
						return (
							<SwiperSlide>
								<img src={imagePath} alt='nature-1' />
							</SwiperSlide>
						)
					})}
					{/* <SwiperSlide>
						<img src={this.props.item.imagePaths[0]} alt='nature-1' />
					</SwiperSlide>
					<SwiperSlide>
						<img src={"./images/slides/slide3.jpg"} alt='nature-1' />
					</SwiperSlide>
					<SwiperSlide>
						<img src={"./images/slides/slide2.jpg"} alt='nature-1' />
					</SwiperSlide> */}
				</Swiper>
			</div>
		);
	}
}