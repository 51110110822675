import React, { Component } from 'react';
// import { Container, a } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Footer from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu />
        {/* <Container> */}
        {this.props.children}
        {/* </Container> */}
        <Footer />
        {/* <footer>
          <div className="panel-type1 center d-flex justify-content-center text-theme" style={{
            backgroundColor: "#EFEAE3BA",
            // backgroundImage: 'url("https://swiperjs.com/demos/images/nature-1.jpg")',
            // height: "2rem"
          }}>
            <div>
              <h1>NAVFPC Commodity</h1>
              <div className="d-flex justify-content-center">
                <div className="social-icon-div center">
                  <img className="social-icon" src={"./images/instagram.svg"} alt="Service Icon" />
                </div>
                <div className="social-icon-div center">
                  <img className="social-icon" src={"./images/facebook.svg"} alt="Service Icon" />
                </div>
                <div className="social-icon-div center">
                  <img className="social-icon" src={"./images/linkedin.svg"} alt="Service Icon" />
                </div>
              </div>
              <div>
                <p className="p4 center">
                  Copyright © 2022 NAVFPC Commodity | Develop By Akshay Ambadkar
                </p>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    );
  }
}
