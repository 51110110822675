import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import { Home } from "./components/Home";
// import { Products } from "./components/Products";
import Products from "./components/Products";
import Admin from "./components/Admin";
import Doc from "./components/Documents";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/documents',
    element: <Doc />
  },
  {
    path: '/admin', //navfpcadmin
    element: <Admin />
  },
  {
    path: '*',
    element: <Home />
  }
];

export default AppRoutes;
