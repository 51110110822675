import React, { Component } from 'react';
// import { data } from "ClientApp/src/assets/homePageData";
// import products from "./ProductData.json";
import "animate.css/animate.compat.css";
// import { CustomImageBackground } from './CustomImageBackground';
import { ProductImageSwiper } from './ProductImageSwiper/ProductImageSwiper';
// import getImagePaths from './getImagePaths';

// const product = products.filter(obj => {
// 	return obj.ProductName === 'Long Pepper (Spikes)';
// });

export class ProductDetails extends Component {

	product = this.props.item.productDetails;

	render() {
		return (
			<div>
				<div>
					{/* <div className='at-back position-top height-70' id='imageSlide'>
						<CustomImageBackground item={{ titleText: 'Product', navText: 'Home > ' + this.product.ProductName, imageSrc: './images/slides/slide2.jpg' }} />
					</div> */}
					<div className="d-flex justify-content-center text-theme">
						<div className="d-flex flex-column">
							<div className="product-details-container">
								<h1 className='center text-theme'>{this.product.ProductName}</h1>

								<div className="product-details-row center">
									<div>
										<img src={"./images/products/" + this.product.ProductName + ".jpg"} alt="Product" />
									</div>
									<div className='text-onleft'>
										<h2>Specifications</h2>
										<table className='product-specs' border={2} cellPadding={5}>
											<tbody>
												{console.log(this.product.Specification[0].purity.length)}
												{this.product.Specification[0].purity.length > 0 &&
													<tr>
														<td>- Purity</td>
														<td>: {this.product.Specification[0].purity}</td>
													</tr>
												}
												{this.product.Specification[0].Style.length > 0 &&
													<tr>
														<td>- Style</td>
														<td>: {this.product.Specification[0].Style}</td>
													</tr>
												}
												{this.product.Specification[0].Color.length > 0 &&
													<tr>
														<td>- Color</td>
														<td>: {this.product.Specification[0].Color}</td>
													</tr>
												}
												{this.product.Specification[0].DryingProcess.length > 0 &&
													<tr>
														<td>- Drying Process</td>
														<td>: {this.product.Specification[0].DryingProcess}</td>
													</tr>
												}
												{this.product.Specification[0].Moisture.length > 0 &&
													<tr>
														<td>- Moisture</td>
														<td>: {this.product.Specification[0].Moisture}</td>
													</tr>
												}
												{this.product.Specification[0].Clean.length > 0 &&
													<tr>
														<td>- Clean</td>
														<td>: {this.product.Specification[0].Clean}</td>
													</tr>
												}
												{this.product.Specification[0].Quality.length > 0 &&
													<tr>
														<td>- Quality</td>
														<td>: {this.product.Specification[0].Quality}</td>
													</tr>
												}
												{this.product.Specification[0].Length.length > 0 &&
													<tr>
														<td>- Length</td>
														<td>: {this.product.Specification[0].Length}</td>
													</tr>
												}
												{this.product.PackagingDetails.length > 0 &&
													<tr>
														<td>- Packaging Details</td>
														<td>: {this.product.PackagingDetails}</td>
													</tr>
												}
												{this.product.SamplePolicy.length > 0 &&
													<tr>
														<td>- Sample Policy</td>
														<td>: {this.product.SamplePolicy}</td>
													</tr>
												}
												{this.product.Appliaction.length > 0 &&
													<tr>
														<td>- Appliaction</td>
														<td>: {this.product.Appliaction}</td>
													</tr>
												}
											</tbody>
										</table>

									</div>
								</div>
							</div>

							<div className="product-swiper-container">
								<ProductImageSwiper item={{ imagePaths: this.product.Images.map(imagePath => "./images/products/" + 1 + "/" + imagePath) }} />
								{/* <ProductImageSwiper item={{ imagePaths: this.product.Images.map(imagePath => "./images/products/" + this.product.Id + "/" + imagePath) }} /> */}
							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}
