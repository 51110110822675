
import React from 'react';
import { Document, Page } from 'react-pdf';
// import cardData from './cardData'
import VCSMPPQCI from './VCSMPPQCI.pdf'
import NAVFPCAuditReport from './NAVFPC Audit Report 2021-22.pdf'
import NAVFPCIEC from './NAVFPC IEC.pdf'
import RegistrationCertificate from './Registration Certificate.pdf'
import Stack from 'react-bootstrap/Stack';
import { Container } from 'reactstrap';


const PdfViewer = ({ }) => {
    return (
        <div class="Container p-5">
            <Stack direction="horizontal" gap={3}>

                <div className="p-2">
                    <img src='images/pdf-icon.png' style={{ width: 70, height: 90 }} />
                </div>
                <div className="p-2">
                    <a
                        href={VCSMPPQCI}
                        target="_blank"
                        rel="noreferrer"
                        style={{color: 'Blue', width:''}}
                    >
                        View VCSMPPQCI pdf
                    </a>
                </div>
                {/* <div className="vr" style={{ height: 100}}/>
                <div className="p-2">
                    <lable>This pdf is...</lable>
                </div> */}
            </Stack>

            <Stack direction="horizontal" gap={3}>

                <div className="p-2">
                    <img src='images/pdf-icon.png' style={{ width: 70, height: 90 }} />
                </div>
                <div className="p-2">
                    <a
                        href={NAVFPCAuditReport}
                        target="_blank"
                        rel="noreferrer"
                        style={{color: 'Blue'}}
                    >
                        View NAVFPCAuditReport pdf
                    </a>
                </div>
                {/* <div className="vr" />
                <div className="p-2">
                    <lable>This pdf is View NAVFPCAuditReport pdf</lable>
                </div> */}
            </Stack>

            <Stack direction="horizontal" gap={3}>

                <div className="p-2">
                    <img src='images/pdf-icon.png' style={{ width: 70, height: 90 }} />
                </div>
                <div className="p-2">
                    <a
                        href={NAVFPCIEC}
                        target="_blank"
                        rel="noreferrer"
                        style={{color: 'Blue'}}
                    >
                        View NAVFPC IEC pdf
                    </a>
                </div>
                {/* <div className="vr" />
                <div className="p-2">
                    <lable>This pdf is View NAVFPC IEC pdf</lable>
                </div> */}
            </Stack>

            <Stack direction="horizontal" gap={3}>

                <div className="p-2">
                    <img src='images/pdf-icon.png' style={{ width: 70, height: 90 }} />
                </div>
                <div className="p-2">
                    <a
                        href={RegistrationCertificate}
                        target="_blank"
                        rel="noreferrer"
                        style={{color: 'Blue'}}
                    >
                        View Registration Certificate pdf
                    </a>
                </div>
                {/* <div className="vr" />
                <div className="p-2">
                    <lable>This pdf is View Registration Certificate</lable>
                </div> */}
            </Stack>

        </div>
    );
}

export default PdfViewer;

// export default function Documents() {
//     return (
//         <div>
//             {/* <object
//                 width="100%"
//                 height="400"
//                 data="./VCSMPPQCI.pdf"
//                 type="application/pdf">
//             </object> */}
//             {/* <Document file=
//             {{
//                 data: pdfFile
//               }}
//             options={{ workerSrc: "/pdf.worker.js" }}
//             >
//                 <Page pageNumber={1} />
//             </Document> */}
//         </div>
//     );
// }

// import React, { useState } from "react";
// import { Document, Page } from "react-pdf";
// import samplePDF from './VCSMPP - QCI.pdf';

// export default function Documents(props) {
//   const [numPages, setNumPages] = useState(null);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   const { pdf } = props;

//   return (
//     <Document
//       file={pdf}
//       options={{ workerSrc: "/pdf.worker.js" }}
//       onLoadSuccess={onDocumentLoadSuccess}
//     >
//       {Array.from(new Array(numPages), (el, index) => (
//         <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//       ))}
//     </Document>
//   );
// }

// import React from 'react';
// import { Document, Page } from "react-pdf";
// import samplePDF from './VCSMPP - QCI.pdf';

// export default function AboutUs() {
//     return (
//         <>
//             <div>
//                 <Document file={samplePDF}>
//                     options={{ workerSrc: "/pdf.worker.js" }}
//                     <Page pageNumber={1} />
//                 </Document>
//             </div>
//         </>
//     );
// }
