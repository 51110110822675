import React, { useState } from 'react';
// import Footer from './Footer';
// import Corousel from './Corousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AboutUs.css';

export default function AboutUs() {
    return (
        <>
            {/* <Corousel/> */}
            <div className="text-center text-lg-start text-muted">
                <div className="container p-4">
                    <div className="row my-4">
                        <h5 className="text-center mb-4">Nagarjana Aushadhi Vanaspati Farmers Producer Company (NAVFPC)</h5>
                        <div className="col-lg-2 col-md-1 mb-4 mb-md-0">
                        </div>
                        <div className="col-lg-4 col-md-6 mb-4 pt-4 mb-md-0" style={{ textAlign: 'justify' }}>
                            <p>

                                Nagarjana Aushadhi Vanaspati Farmers Producer Company (NAVFPC) is located in
                                Vidarbha region of Maharashtra state, India. Medicinal plants are cultivated in Amravati,
                                Akola and Buldhana Districts of Vidarbha region on large area. It is having semi-arid
                                tropic climate. Long pepper is majorly grown by the small and marginal farmers. These

                                farmers belonging to the “Bari” community as they have imparted the skill to grow this
                                crop from generation together. They have to manage temperature and light beside
                                water according to season.
                                Long pepper’s dried spike, different quality roots and stem, Cassia Tora seed,
                                Mucunapruriens. We are organizing capacity building training &amp; work shop for the
                                growers as well as marketing their products in Pan India.
                                The packaging is of 50 kg per bag by weight packed in waterproof plastic bag covered
                                by reused gunny bag if the quantity is less than 50 kg then we pack as per customers
                                demand.
                            </p>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4 mb5">
                            <div className='bg-image hover-zoom text-center m-4 mb-5 pt-2'>
                                <img src='images/AboutUs/1.jpeg' className='w-100 img-fluid' />
                            </div>
                            <div className='bg-image hover-zoom text-center m-4 pt-4'>
                                <img src='images/AboutUs/2.jpg' className='w-100 img-fluid' />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-4 mb-md-0 mt5"></div>
                    </div>
                </div>
            </div>
            <div
                className="bg-image card shadow-1-strong about-us-style1"
                style={{ backgroundImage: "url('images/AboutUs/Big Images.jpg')" }}
            >
                <div className="text-center text-lg-start text-white overlayAboutUs about-us-style1"
                    style={{
                        // position: 'fixed',
                        // display: 'none',
                        // width: '100%', 
                        // height: '100%', 
                        // top: '0',
                        // left: '0',
                        // right: '0',
                        // bottom: '0',
                        backgroundColor: 'rgba(150, 150, 150, 0.5)'
                        // zIndex: '99', 
                        // cursor: 'pointer'
                    }}>
                    <div className="container p-4">
                        <div className="row my-4">
                            <h5 className="text-center mb-5">Role
                                of NAVFPC to improve the Productivity,
                                Quality and Marketing of Long Pepper</h5>
                            <div className="col-lg-2 col-md-1 mb-4 mb-md-0">
                            </div>
                            <div className="col-lg-8 col-md-12 mb-1 mb-md-0" style={{ textAlign: 'justify' }}>
                                <p>
                                    Long Pepper is successfully grown in 1000 hectare area of Amravati, Akola and Buldhana districts. 
                                    The total annual production of dried spicks of Long Pepper is 600 MT. This region is land locked and 
                                    this crop is irrigated troughout the year majorly by micro irrigation system. The water resources from 
                                    the dug well or tube well. The Long Pepper is vine in nature and for better production these vines ties 
                                    vertically on the supporting plant either by natural or plastic thread. This is time consuming labor 
                                    intensive and skilled work which is perform by ‘Bari’ community people who are the locals of this area. 
                                    The vine is annual in nature and economic life of the vine is 2-3 years in most of the cases. The planting 
                                    material of the Long Pepper and its planting area is the same over the decade. Therefore, it faces 
                                    microbial disease problem frequently. NAVFPC is joining the hands with local agriculture university and 
                                    others subject matters specialist to find out the non- chemical solution on this constraint. This will 
                                    lead to improve life of the vine and raise the productivity of the crop. It will improve the farm income 
                                    and the farmers depend on this crop. This Long Pepper is known with ‘Choti pippal’ in domestic trade as 
                                    its size particularly length is less compare to other Long Pepper in the trade. Its picking is done form 
                                    October to January. The spike are dried on open farm ground faces the problem for untimely rains. During 
                                    picking some spikes are over matured and faces the problem of more moisture during the process of solar 
                                    drying. In both the condition the spikes holds more moisture and microbial load. This condition is 
                                    avoided by the many reputed medicinal drug manufacturing industries. We are trying to aware the growers 
                                    about this situation and trying to improve the crop quality and shelf life. Long pepper spikes are mostly 
                                    use in medicinal drug manufacturing industries. Whereas its roots, root nodes and stem use for culinary 
                                    purpose. Dried spikes are packed in plastic or PP bags covered by gunny bags for the safety from the 
                                    contamination and moisture during transport. This bags contains 50 Kgs of dried spikes.
                                </p>
                            </div>
                            <div className="col-lg-2 col-md-6 mb-4 mb-md-0"></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}