import React, { useState, useEffect } from "react";
import { ReactComponent as CloseMenu } from "../assets/x.svg";
import { ReactComponent as MenuIcon } from "../assets/menu.svg";
import Logo from "../assets/logo.png";
import "./NavHeader.css";
import { Dropdown, DropdownItem, ListGroup, ListGroupItem } from "reactstrap";
import { NavDropdown } from 'react-bootstrap';
import products from "./ProductData.json";
import { Navbar, Nav } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';


// const product = products.filter(obj => {
// 	return obj.ProductName === 'Long Pepper (Spikes)';
// });
const phoneNumber = '9822724939';

const handlePhoneIconClick = () => {
  window.location.href = `tel:${phoneNumber}`;
};

const handleWhatsAppIconClick = () => {

  window.location.href = 'https://api.whatsapp.com/send?phone=9822724939';
};

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  var areProductsVisible = false;

  const showProducts = () => { areProductsVisible = false };

  const navItems = [
    {
      "id": "nav-home",
      "label": "Home",
      "urlText": "/home"
    },
    {
      "id": "nav-products",
      "label": "Products",
      "urlText": "/products"
    },
    {
      "id": "nav-about-us",
      "label": "About Us",
      "urlText": "/about-us"
    },
    {
      "id": "nav-contact-us",
      "label": "Contact Us",
      "urlText": "/contact-us"
    },
    {
      "id": "nav-documents",
      "label": "Documents",
      "urlText": "/documents"
    },
    // {
    //   "id": "nav-admin",
    //   "label": "Admin",
    //   "urlText": "/admin"
    // },
  ];

  // const updateNavItemsColor = (navId) => {
  //   navItems.forEach(item => {
  //     document.querySelector("#" + item.id).classList.remove('nav-selected-text');      
  //   });
  //   document.querySelector("#" + navId).classList.add('nav-selected-text');
  // }

  // const onNavItemsLoadHandler = () => {
  //   document.querySelector("#nav-" + this.props.item.currentPath).classList.add('nav-selected-text');
  // }

  useEffect(() => {
    let currentPath = window.location.pathname.replace('/', '');
    document.querySelector("#nav-" + currentPath) && document.querySelector("#nav-" + currentPath).classList.add('nav-selected-text');
  }, [])

  return (
    <div className="header">
      <div className="logo-nav">
        <div className="logo-container">
          <a href="#">
            <img src={Logo} alt="logo" />
            {/* <Logo className="logo" /> */}
          </a>
        </div>
        <ul className={click ? "nav-options active" : "nav-options"}>
          {navItems.map(item => {
            return (
              <li className="option" onClick={closeMobileMenu}>
                <a href={item.urlText} id={item.id}>{item.label}</a>
              </li>
            )
          })}
          <li>
          <Nav.Link className="phone-link" onClick={handlePhoneIconClick}>
            <FaPhone className="nav-icon tada-animation" style={{ color: '#ef3038', marginRight: '1px' }} />
            <span className="call-us-text" style={{ color: '#ef3038' }}>Call Us</span>
          </Nav.Link>
          </li>
          <li>
          <Nav.Link className="whatsapp-link" onClick={handleWhatsAppIconClick}>
            <FaWhatsapp className="nav-icon tada-animation" style={{ color: '#25D366', marginRight: '1px' }} />
            <span className="whatsapp-text" style={{ color: '#25D366' }}>WhatsApp</span>
          </Nav.Link>
          </li>
          {/* <li className="option" onClick={closeMobileMenu}>
            <a href="/home" id="nav-home" onClick={() => addclass()}>Home</a>
          </li>
          <li className="option" id="nav-products" onClick={closeMobileMenu}>
            <a href="/products">Products</a>
          </li>
          <li className="option" id="nav-about-us" onClick={closeMobileMenu}>
            <a href="/about-us">About Us</a>
          </li>
          <li className="option" id="nav-documents" onClick={closeMobileMenu}>
            <a href="#">Documents</a>
          </li>
          <li className="option" id="nav-admin" onClick={closeMobileMenu}>
            <a href="#">Admin</a>
          </li> */}
        </ul>        
      </div>
      {/* <ul className="signin-up">
        <li className="sign-in" onClick={closeMobileMenu}>
          <a href="#">SIGN-IN</a>
        </li>
        <li onClick={closeMobileMenu}>
          <a href="" className="signup-btn">
            SIGN-UP
          </a>
        </li>
      </ul> */}
      <div className="mobile-menu" onClick={handleClick}>
        {click ? (
          <CloseMenu className="menu-icon border-inset" />
        ) : (
          <MenuIcon className="menu-icon border-outset"/>
        )}
      </div>
    </div>
  );
};

export default Header;
