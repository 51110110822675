import React, { Component } from 'react';
import { fadeInUp } from 'react-animations';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
	zoomIn: {
		animationName: fadeInUp,
		animationDuration: '1s',
		// animationIterationCount: "infinite",
	},
	none: {}
  })

export class CustomImageAnimator extends Component {
	render() {
		return (
				<div className={css(this.props.item.animeOrNot === "true" ? styles.zoomIn : styles.none)}>
					{this.props.item.element}
				</div>
		  );
	  }
}