import React, { Component } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "./CustomImageSwiper.css";

import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import { CustomImageAnimator } from './../CustomImageAnimator';


// const slide1Obj = {
// 	element: <img src={"./images/slides/slide1.jpg"} alt='nature-1' />,
// 	animeOrNot: "false"
// };

export class CustomImageSwiper extends Component {
	render() {
		return (
			<div className="div1">
				<Swiper
					grabCursor={true}
					centeredSlides={true}
					speed={1000}
					effect={"coverflow"}
					loop={true}
					// autoplay={{
					// 	delay: 10000,
					// 	disableOnInteraction: false,
					// }}
					pagination={{
						clickable: true,
					}}
					// parallax={true}
					navigation={true}
					modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
					className="mySwiper"
				>
					<SwiperSlide>
						<div>
							<CustomImageAnimator item={
								{
									element: <img src={"./images/slides/slide111.jpg"} alt='nature-1' />,
									animeOrNot: ""
								}
							} />
						</div>
						<div className='text-light' style={{ position: "absolute", zIndex: 2 }}>
							<CustomImageAnimator item={
								{
									element: <div className='home-welcome-text' >
										<h2 className='title-white'>Welcome to</h2>
										<div className="main-title-container">
											<h1 className='h1-big'>NAGARJUNA AUSHADHI VANASPATI FARMERS PRODUCER COMPANY</h1>
										</div>
									</div>,
									animeOrNot: "true"
								}
							} />
						</div>
					</SwiperSlide>
					{/* <SwiperSlide>
						<CustomImageAnimator item={
							{
								element: <img src={"./images/slides/slide2.jpg"} alt='nature-1' />,
								animeOrNot: ""
							}
						} />
					</SwiperSlide>
					<SwiperSlide>
						<CustomImageAnimator item={
							{
								element: <img src={"./images/slides/slide3.jpg"} alt='nature-1' />,
								animeOrNot: ""
							}
						} />
					</SwiperSlide> */}
				</Swiper>
			</div>
		);
	}
}